import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "value"]
  connect() {
    if (this.hasInputTarget) this.valueTarget.textContent = this.inputTarget.value  || '#000000'
  }

  disconnect() {}

  change(event) {
    const newValue = event.target.value
    this.valueTarget.textContent = newValue
  }
}