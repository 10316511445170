import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [
    "yunooTitle",
    "description",
    "sessionsSelectionMode",
    "funding",
    "cpfTrainingUrl",
    "defaultPriceWithoutTaxCents",
    "defaultPriceWithTaxCents",
    "displayPriceKind",
    "priceRecurringInterval",
    "riseUpSessionGroups",
    "riseUpSessions",
    "threeSixtyLearningSessions",
    "threeSixtyLearningPathSessions",
    "canvasInstructureSections",
    "learningItem",
    "priceTiers",
    "priceCentsCurrencies",
    "stripePaymentMode",
    "allowOnlinePayment",
    "stripeProductName",
    "stripeProductDescription",
    "paystackProductName",
    "paystackProductDescription",
    "showPriceDetails",
    "allowRegistrationRequest",
    "quoteRequest",
    "invoiceRequest",
  ]
  static values = {
    lmsType: String,
    paymentProcessor: String,
    invoicingService: String,
    translations: Object,
    defaultLocale: String
  }
  
  connect() {
    // shows or hide cpf link based on the type of product funding selected
    // if the current url path is /admin/products/new
    // only handles NEW
    if (window.location.pathname == '/admin/products/new') {
      this.handleLearningItem()
    }
    this.handleFundingUI()
    this.handleCheckoutTypeUI()
    this.handleCustomSessionsSelectionFieldsUI()
    this.handleSessionSelectionModeUI()
    this.invoiceRequestCheckbox = document.getElementById('product_invoice_request')
    if (this.hasAllowOnlinePaymentTarget) {
      this.allowOnlinePaymentCheckbox = document.getElementById('product_allow_online_payment')
    }
    if (this.paymentProcessorValue == "stripe") {
      this.handleStripeUI()
    } else if (this.paymentProcessorValue == "paystack") {
      this.handlePaystackUI()
    }
    this.handleInvoiceRequestUI()
  }

  handleInvoiceRequestUI(){
    if (this.invoicingServiceValue == "stripe") {
      this.invoiceRequestCheckbox.addEventListener('change', () => this.handleDisplayFieldsStripe())
    }
  }

  handleStripeUI(){
    if (!this.hasAllowOnlinePaymentTarget) {
      return
    }    
    this.handleDisplayFieldsStripe()
    this.allowOnlinePaymentCheckbox.addEventListener('change', () => this.handleDisplayFieldsStripe())
  }

  handleDisplayFieldsStripe(){
    if (!this.allowOnlinePaymentCheckbox && !this.invoiceRequestCheckbox) {
      return
    }
    if (this.allowOnlinePaymentCheckbox.checked || this.invoiceRequestCheckbox.checked) {
      this.showStripeFields()
    } else {
      this.hideStripeFields()
    }
  }

  handleDisplayFieldsPaystack(){
    if (!this.allowOnlinePaymentCheckbox) {
      return
    }
    if (this.allowOnlinePaymentCheckbox.checked) {
      this.showStripeFields()
    } else {
      this.hideStripeFields()
    }
  }


  handleLearningItem(){
    this.learningItemSelector = document.getElementById('product_learning_item_value')
    // return unless this.learningItemSelector
    if (!this.learningItemSelector) {
      return
    }
    this.learningItemSelector.selectize.on('change', (value) => {
      if (value == "") {
        return
      }
      this.handlePrefillInputs()
    })
  }

  async handlePrefillInputs(){
    // user action modal "do you want to prefill inputs?" if true, then prefill
    const modalControllerEl = document.querySelector('[data-controller="user-action-modal"]')
    const application = this.application
    const modalController = application.getControllerForElementAndIdentifier(modalControllerEl, "user-action-modal")
    var userActionResponse = await modalController.showModal({
      title: this.translationsValue['new']['prefill_inputs_title'] ?? "Prefill with existing data",
      description: this.translationsValue['new']['prefill_inputs'] ?? "Do you want to prefill the form (title, description, skills, author, categories) with the data from your LMS ?",
      cancel: this.translationsValue['new']['cancel'] ?? "Cancel",
      validate: this.translationsValue['new']['validate'] ?? "Validate"
    })
    if (userActionResponse) {
      // get the learning item value
      let learningItemValue = this.learningItemSelector.value
      if (learningItemValue == "") {
        return
      }
      console.log(learningItemValue)
      console.log(learningItemValue)

      // fetch the learning item data
      Rails.ajax({
        url: `/admin/learning_items/data-json`,
        type: 'POST',
        data: `learning_item_global_id=${learningItemValue}`,
        success: (data) => {
          console.log(data)
          // prefill the inputs
          this.prefillInputs(data)
        },
        error: (data) => {
          console.log(data)
        }
      })
    }
  }

  yunooTitleMaxLength(){
    return 249
  }



  prefillInputs(data){
    if (data['yunoo_title']) {
      document.getElementById(`product_yunoo_title_${this.defaultLocaleValue}`).value = data['yunoo_title']
      if (document.getElementById(`product_stripe_product_name`)) {
        document.getElementById(`product_stripe_product_name`).value = data['yunoo_title'].substring(0, this.yunooTitleMaxLength());
      }
      document.getElementById(`product_seo_title_${this.defaultLocaleValue}`).value = data['yunoo_title'].substring(0, this.yunooTitleMaxLength());
      document.getElementById(`product_og_title_${this.defaultLocaleValue}`).value = data['yunoo_title'].substring(0, this.yunooTitleMaxLength());
    } else {
      document.getElementById('product_yunoo_title').value = ""
    }
    if (data['description']) {
      document.getElementById(`product_description_${this.defaultLocaleValue}`).value = data['description']
      if (document.getElementById(`product_stripe_product_description`)) {
        document.getElementById(`product_stripe_product_description`).value = data['description'].substring(0, 249);
      }
      document.getElementById(`product_short_description_${this.defaultLocaleValue}`).value = data['description'].substring(0, 249);
      document.getElementById(`product_meta_description_${this.defaultLocaleValue}`).value = data['description'].substring(0, 249);
      document.getElementById(`product_og_description_${this.defaultLocaleValue}`).value = data['description'].substring(0, 249);

    } else {
      document.getElementById(`product_description_${this.defaultLocaleValue}`).value = ""
    }
    if (data['author_id']) {
      // selectize the author automatically
      document.getElementById('product_author_id').selectize.setValue(data['author_id'])
    } else { 
      // reset the author
      document.getElementById('product_author_id').selectize.setValue([])
    }
    if (data['skill_ids']){
      // selectize the skills automatically
      document.getElementById('product_skill_ids').selectize.setValue(data['skill_ids'])
    } else {
      // reset the skills
      document.getElementById('product_skill_ids').selectize.setValue([])
    }
    if (data['available_language_ids']) {
      document.getElementById('product_language_ids').selectize.setValue(data['available_language_ids'])
    } else {
      // reset the language ids
      document.getElementById('product_language_ids').selectize.setValue([])
    }
    if (data['language']){
      // should switch language if necessary
    }
    if (data['duration_in_minutes']){
        document.getElementById('product_duration_in_minutes').value = data['duration_in_minutes']
      // should set the duration in minutes
    }
    if (data['category_ids']){
      document.getElementById('product_category_ids').selectize.setValue(data['category_ids'])
    } else {
      // reset the category ids
      document.getElementById('product_category_ids').selectize.setValue([])
    }
  }
  handleFundingUI(){
    this.productFundingInput = document.getElementById('product_funding')
    this.productFundingInput.selectize.on('change', () => this.handleDisplayFieldsProductFunding())
    this.handleDisplayFieldsProductFunding()
  }

  // shows or hide cpf link based on the type of product funding selected
  handleDisplayFieldsProductFunding(){
    if (this.productFundingInput.value == 'cpf' && this.hascpfTrainingUrlTarget) {
      this.cpfTrainingUrlTarget.classList.remove('hidden')
    } else if (this.hascpfTrainingUrlTarget) {
      this.cpfTrainingUrlTarget.classList.add('hidden')
    }
  }

  handleCheckoutTypeUI(){
    this.checkoutTypeSelector = document.getElementById('product_acquisition_mode')
    this.handleDisplayFieldsCheckoutType()
    this.checkoutTypeSelector.selectize.on('change', () => this.handleDisplayFieldsCheckoutType());
  }

  handleDisplayFieldsCheckoutType(){
    let value = this.checkoutTypeSelector.value
    if (value == 'payment'){
      console.log('value is payment')
      this.hideSubscriptionFields()
      this.hideTieredFields()
      this.hideTieredWithSubscriptionFields()
      this.showPaymentFields()
      if (this.paymentProcessorValue == "stripe") {
        this.showStripeFields()
      } else if (this.paymentProcessorValue == "paystack") {
        this.showPaystackFields()
      }
    } else if (value == 'subscription') {
      console.log('subscription')
      this.hidePaymentFields()
      this.hideTieredFields()
      this.hideTieredWithSubscriptionFields()
      this.showSubscriptionFields()
      if (this.paymentProcessorValue == "stripe") {
        this.showStripeFields()
      } else if (this.paymentProcessorValue == "paystack") {
        this.showPaystackFields()
      }
    } else if (value == 'free') {
      console.log('value is free')
      this.hidePaymentFields()
      this.hideSubscriptionFields()
      this.hideTieredWithSubscriptionFields()
      this.hideTieredFields()
      if (this.paymentProcessorValue == "stripe") {
        this.hideStripeFields()
      } else if (this.paymentProcessorValue == "paystack") {
        this.hidePaystackFields()
      }
    } else if (value == 'tiered') {
      this.hidePaymentFields()
      this.hideSubscriptionFields()
      this.hideTieredWithSubscriptionFields()
      this.showTieredFields()
      if (this.paymentProcessorValue == "stripe") {
        this.showStripeFields()
      }
    } else if (value == 'tiered_with_subscription') {
      this.hidePaymentFields()
      this.hideSubscriptionFields()
      this.hideTieredFields()
      this.showTieredWithSubscriptionFields()
      if (this.paymentProcessorValue == "stripe") {
        this.showStripeFields()
      }
    } else if (value == 'no_payment') {
      this.hidePaymentFields()
      this.hideStripeFields()
      this.hideSubscriptionFields()
      this.hideTieredFields()
      this.hideTieredWithSubscriptionFields()
    }
  }

  handleCustomSessionsSelectionFieldsUI(){
    this.learningItemSelector = document.getElementById('product_learning_item_value')
    if (!this.learningItemSelector) {
      return
    }
    this.sessionSelectionMode = document.getElementById("product_sessions_selection_mode");
    this.sessionSelectionMode.selectize.on('change', () => this.handleDisplaySelectSessionsFields())
    this.learningItemSelector.selectize.on('change', () => this.handleDisplaySelectSessionsFields())
    this.handleDisplaySelectSessionsFields()
  }

  handleDisplaySelectSessionsFields() {
    if (this.threeSixtyLearningProgramCustomSelectionSessions()) {
      this.threeSixtyLearningSessionsTarget.classList.remove('hidden')
      this.threeSixtyLearningPathSessionsTarget.classList.add('hidden')
    }
    else if (this.threeSixtyLearningPathCustomSelectionSessions()) {
      this.threeSixtyLearningSessionsTarget.classList.add('hidden')
      this.threeSixtyLearningPathSessionsTarget.classList.remove('hidden')
    }
    else if (this.riseUpTrainingCustomSelectionSessions()) {
      if (this.hasRiseUpSessionGroupsTarget) {
        this.riseUpSessionGroupsTarget.classList.remove('hidden')
      } else if (this.hasRiseUpSessionsTarget) {
        this.riseUpSessionsTarget.classList.remove('hidden')
      }
    } 
    else if (this.canvasInstructureCourseCustomSelectionSessions()) {
      this.canvasInstructureSectionsTarget.classList.remove('hidden')
    }
    else {
      if (this.lmsTypeValue == 'ThreeSixtyLearning') {
        this.threeSixtyLearningSessionsTarget.classList.add('hidden')
        this.threeSixtyLearningPathSessionsTarget.classList.add('hidden')
      }
      if (this.lmsTypeValue == 'RiseUp') {
        if (this.hasRiseUpSessionGroupsTarget) {
         this.riseUpSessionGroupsTarget.classList.add('hidden')
        } else if (this.hasRiseUpSessionsTarget) {
          this.riseUpSessionsTarget.classList.add('hidden')
        }
      }

      if (this.lmsTypeValue == 'CanvasInstructure') {
        this.canvasInstructureSectionsTarget.classList.add('hidden')
      }
    }
  }

  riseUpTrainingCustomSelectionSessions(){
    return this.learningItemSelector.value.includes('RiseUp::Training') && this.sessionSelectionMode.value == 'custom'
  }

  canvasInstructureCourseCustomSelectionSessions(){
    return this.learningItemSelector.value.includes('CanvasInstructure::Course') && this.sessionSelectionMode.value == 'custom'
  }

  threeSixtyLearningPathCustomSelectionSessions(){
    return this.learningItemSelector.value.includes('ThreeSixtyLearning::Path') && this.sessionSelectionMode.value == 'custom'
  }

  threeSixtyLearningProgramCustomSelectionSessions(){
    return this.learningItemSelector.value.includes('ThreeSixtyLearning::Program') && this.sessionSelectionMode.value == 'custom'
  }

  handleSessionSelectionModeUI() {
    // this.sessionsSelectionModeTarget.classList.add('hidden')
    if (!this.learningItemSelector) {
      return
    }
    this.learningItemSelector.selectize.on('change', () => this.handleDisplayFieldsSessionSelectionMode())
    this.handleDisplayFieldsSessionSelectionMode()
  }

  handleDisplayFieldsSessionSelectionMode(){
    let value = this.learningItemSelector.value
    if (value.includes('ThreeSixtyLearning::Program') || value.includes('ThreeSixtyLearning::Path') || value.includes('RiseUp::Training') || value.includes('CanvasInstructure::Course')) {
      this.sessionsSelectionModeTarget.classList.remove('hidden')
    } else {
      this.sessionSelectionMode.selectize.setValue("")
      if (this.hasSessionSelectionModeTarget) {
        this.sessionsSelectionModeTarget.classList.add('hidden')
      }
    }
  }

  tieredFields(){
    let fields =  []

    if (this.hasPriceTiersTarget) {
      fields.push(this.priceTiersTarget)
    }

    if (this.hasShowPriceDetailsTarget) {
      fields.push(this.showPriceDetailsTarget)
    }
    if (this.hasAllowOnlinePaymentTarget) {
      fields.push(this.allowOnlinePaymentTarget);
    }

    if (this.hasQuoteRequestTarget) {
      fields.push(this.quoteRequestTarget)
    }

    if (this.hasStripePaymentModeTarget) {
      fields.push(this.stripePaymentModeTarget)
    }

    return fields
  }

  stripeFields(){
    if (!this.hasStripeProductNameTarget) {
      return []
    }
    return [this.stripeProductNameTarget, this.stripeProductDescriptionTarget]
  }

  paystackFields() {
    if (!this.hasPaystackProductNameTarget) {
      return []
    }
    return [this.paystackProductNameTarget, this.paystackProductDescriptionTarget]
  }
  tieredWithSubscriptionFields(){
    let fields = []
    if (this.hasPriceTiersTarget) {
      fields.push(this.priceTiersTarget)
    }
    if (this.hasPriceRecurringIntervalTarget) {
      fields.push(this.priceRecurringIntervalTarget)
    }
    if (this.hasShowPriceDetailsTarget) {
      fields.push(this.showPriceDetailsTarget)
    }

    if (this.hasQuoteRequestTarget) {
      fields.push(this.quoteRequestTarget)
    }

    if (this.hasStripePaymentModeTarget) {
      fields.push(this.stripePaymentModeTarget)
    }

    if (this.hasAllowOnlinePaymentTarget) {
      fields.push(this.allowOnlinePaymentTarget);
    }

    return fields
  }

  paymentFields() {
    let fields = [];
    if (this.hasDefaultPriceWithoutTaxCentsTarget) {
      fields.push(this.defaultPriceWithoutTaxCentsTarget);
    }

    if (this.hasDefaultPriceWithTaxCentsTarget) {
      fields.push(this.defaultPriceWithTaxCentsTarget);
    }

    if (this.hasDisplayPriceKindTarget) {
      fields.push(this.displayPriceKindTarget);
    }

    if (this.hasShowPriceDetailsTarget) {
      fields.push(this.showPriceDetailsTarget);
    }

    if (this.hasPriceCentsCurrenciesTarget) {
      fields.push(this.priceCentsCurrenciesTarget);
    }

    if (this.hasStripePaymentModeTarget) {
      fields.push(this.stripePaymentModeTarget);
    }

    if (this.hasQuoteRequestTarget) {
      fields.push(this.quoteRequestTarget);
    }

    if (this.hasAllowOnlinePaymentTarget) {
      fields.push(this.allowOnlinePaymentTarget);
    }
    return fields;
  }
  
  subscriptionFields() {
    let fields = [];
    
    if (this.hasPriceRecurringIntervalTarget) {
      fields.push(this.priceRecurringIntervalTarget);
    }

    if (this.hasDefaultPriceWithoutTaxCentsTarget) {
      fields.push(this.defaultPriceWithoutTaxCentsTarget);
    }

    if (this.hasDefaultPriceWithTaxCentsTarget) {
      fields.push(this.defaultPriceWithTaxCentsTarget);
    }

    if (this.hasShowPriceDetailsTarget) {
      fields.push(this.showPriceDetailsTarget);
    }

    if (this.hasDisplayPriceKindTarget) {
      fields.push(this.displayPriceKindTarget);
    }
    
    if (this.hasPriceCentsCurrenciesTarget) {
      fields.push(this.priceCentsCurrenciesTarget);
    }

    if (this.hasQuoteRequestTarget) {
      fields.push(this.quoteRequestTarget);
    }

    if (this.hasStripePaymentModeTarget) {
      fields.push(this.stripePaymentModeTarget)
    }

    if (this.hasAllowOnlinePaymentTarget) {
      fields.push(this.allowOnlinePaymentTarget);
    }
    
    return fields;
  }

  showPaymentFields(){
    this.paymentFields().forEach(target => target.classList.remove('hidden'))
  }

  showSubscriptionFields(){
    this.subscriptionFields().forEach(target => target.classList.remove('hidden'))
  }

  hidePaymentFields(){
    this.paymentFields().forEach(target => target.classList.add('hidden'))
  }

  hideSubscriptionFields(){
    this.subscriptionFields().forEach(target => target.classList.add('hidden'))
  }

  hideTieredFields(){
    this.tieredFields().forEach(target => target.classList.add('hidden'))
  }
  showTieredFields(){
    this.tieredFields().forEach(target => target.classList.remove('hidden'))
  }

  hideStripeFields(){
    this.stripeFields().forEach(target => target.classList.add('hidden'))
  }

  hidePaystackFields(){
    this.paystackFields().forEach(target => target.classList.add('hidden'))
  }

  showPaystackFields(){
    this.paystackFields().forEach(target => target.classList.remove('hidden'))
  }

  showStripeFields(){
    this.stripeFields().forEach(target => target.classList.remove('hidden'))
  }

  hideTieredWithSubscriptionFields(){
    this.tieredWithSubscriptionFields().forEach(target => target.classList.add('hidden'))
  }

  showTieredWithSubscriptionFields(){
    this.tieredWithSubscriptionFields().forEach(target => target.classList.remove('hidden'))
  }

}
