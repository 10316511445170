import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
  static targets = ['field', 'button']
  static values = { lang: String }

  connect() {
    this.makeButtonActive()
    this.handleTranslatableLocale()
  }

  showFields(lang) {
    this.fieldTargets.forEach(element => {
      if (element.dataset.lang == lang) {
        element.classList.remove('hidden')
      } else {
        if (!element.classList.contains('hidden')) {
          element.classList.add('hidden')
        }
      }
    });
  }

  /**
   * Handles the translatable locale selection from select input.
   * 
   * This method initializes the translatable locale select input, sets its value to the first option on page load,
   * and adds an event listener to handle changes in the select input value. When the value changes,
   * it calls the `showFields` method with the selected value.
   * 
   * Translatable locale view path is `app/views/admin/application/_locales_tabs.html.erb`.
   * 
   * @returns {void}
   */
  handleTranslatableLocale(){
    this.translatableSelector = document.getElementById('translatable_locale')
    if (!this.translatableSelector) {
      return
    }
    const selectizeInstance = this.translatableSelector.selectize
    const firstOptionValue = selectizeInstance.options[Object.keys(selectizeInstance.options)[0]].value
    selectizeInstance.setValue(firstOptionValue)
    selectizeInstance.on('change', (value) => {
      console.log(value)
      if (value == "") {
        return
      }
      this.showFields(value)
    })
  }

  makeButtonActive() {
    this.buttonTargets.forEach(buttonTarget => {
      if (buttonTarget.dataset.lang == this.langValue)
        buttonTarget.classList.add('active')
      else
        buttonTarget.classList.remove('active')
    })
  }
}
