import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "fileName", "emptyZone"]

  connect() {
    if (this.hasInputTarget && this.inputTarget.files.length > 0) {
      this.updateFileName(this.inputTarget.files[0])
    }
  }

  triggerFileInput() {
    this.inputTarget.click()
  }

  handleFileChange(event) {
    const file = event.target.files[0]
    if (file) {
      this.updateFileName(file)
    }
  }

  updateFileName(file) {
    this.fileNameTargets.forEach(element => {
      element.textContent = file.name
      element.setAttribute('title', file.name)
    })
  }
}