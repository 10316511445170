import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["expandable", "subitem"]
  
  connect() {
    this.animating = false
    this.subitemTargets.forEach((subitem, index) => {
      subitem.style.transitionDelay = `${(index + 1) * 50}ms`
    })
  }

  toggle() {
    if (this.animating) return
    
    const expandableHeight = this.expandableTarget.scrollHeight
    
    if (this.element.getAttribute('aria-expanded') === 'true') {
      this.collapse(expandableHeight)
    } else {
      this.expand(expandableHeight)
    }
  }

  collapse(expandableHeight) {
    this.animating = true
    this.element.setAttribute('aria-expanded', 'false')
    this.expandableTarget.style.height = `${expandableHeight}px`
    
    setTimeout(() => {
      this.expandableTarget.style.height = '0px'
      this.expandableTarget.style.overflow = 'hidden'
    }, 10)
    
    setTimeout(() => {
      this.animating = false
    }, 500)
  }

  expand(expandableHeight) {
    this.animating = true
    this.element.setAttribute('aria-expanded', 'true')
    this.expandableTarget.style.height = `${expandableHeight}px`
    
    setTimeout(() => {
      this.expandableTarget.style.height = 'auto'
      this.expandableTarget.style.overflow = 'visible'
      this.animating = false
    }, 500)
  }
}
