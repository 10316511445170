import { Controller } from '@hotwired/stimulus';
import '@selectize/selectize/dist/js/selectize.min.js'
import '@selectize/selectize/dist/css/selectize.css'
export default class extends Controller {
  static values = {
    translations: Object,
  }
  connect() {
    const _that = this
    $("select").each(function() {
      /**
       * Initializes the selectize plugin on all <select> elements within the current context.
       * For each <select> element, it sets up the placeholder text and parent container for the dropdown.
       * Placeholder text is taken from the translationsValue object, which is passed gloablly from '/app/views/layouts/admin/application.html.erb'.
       * If the <select> element has the 'multiple' attribute, it adds the Selectize 'remove_button' plugin.
       */
      const placeholder = _that.translationsValue?.[$(this).attr('id')] ?? $(this).closest('.field-unit').find('label').text()
      const parent = $(this).closest('.field-unit')
      $(this).selectize({
        ...$(this).attr('multiple') && {plugins: ['remove_button']},
        dropdownParent: parent,
        placeholder: placeholder
      });
    });
  }
}
