import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["modal", "title", "description", "cancel", "validate", "close"]

  connect() {
  }

  open({ title, description, cancel, validate }) {
    this.titleTarget.textContent = title
    this.descriptionTarget.textContent = description
    this.cancelTarget.textContent = cancel
    this.validateTarget.textContent = validate
    this.modalTarget.style.display = ''
    this.modalTarget.classList.remove('closed')
  }

  close() {
    this.modalTarget.classList.add('closed')
  }

  cancel() {
    this.close()
    this.resolvePromise(false)
  }

  validate() {
    this.close()
    this.resolvePromise(true)
  }

  resolvePromise(value) {
    if (this.promiseResolve) {
      this.promiseResolve(value)
    }
  }

  showModal({ title, description, cancel, validate }) {
    return new Promise((resolve) => {
      this.promiseResolve = resolve
      this.open({ title, description, cancel, validate })
    })
  }
}