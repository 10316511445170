import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menu", "trigger"]
  
  connect() {
    this.handleClickOutside = this.handleClickOutside.bind(this)
    document.addEventListener('click', this.handleClickOutside)
  }

  disconnect() {
    document.removeEventListener('click', this.handleClickOutside)
  }

  toggle(event) {
    const menuId = event.currentTarget.getAttribute('data-floating-menu-trigger')
    const menu = this.menuTargets.find(menu => 
      menu.getAttribute('data-floating-menu') === menuId
    )
    
    if (menu) {
      menu.classList.toggle('closed')
    }
  }

  handleClickOutside(event) {
    this.menuTargets.forEach(menu => {
      if (!menu.classList.contains('closed') &&
          !menu.contains(event.target) && 
          !event.target.hasAttribute('data-floating-menu-trigger') &&
          event.target.closest('[data-floating-menu-trigger]')?.getAttribute('data-floating-menu-trigger') !== menu.getAttribute('data-floating-menu')) {
        menu.classList.add('closed')
      }
    })
  }
}